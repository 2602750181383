@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}
.brand-img {
  max-width: 300px;
  margin-inline: auto;
}

p {
  color: #bf1650;
}

.form {
  max-width: 1200px;
  margin-inline: auto;
}

.MuiInputLabel-shrink {
  color: #67c8f0 !important;
}

.MuiInputLabel-root,
.MuiMenuItem-root {
  font-family: "Montserrat", sans-serif !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-notchedOutline:hover {
  border: 2px solid #67c8f0 !important;
}

.MuiOutlinedInput-input {
  font-family: "Montserrat", sans-serif !important;
}

.MuiFormControlLabel-label {
  font-family: "Montserrat", sans-serif !important;
}

label {
  font-family: "Montserrat", sans-serif !important;
}